import React from 'react'
import Content from './Content'

const HomePage = () => (
  <section>
    <Content />
  </section>
)

export default HomePage
